import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import Repository from './DealCard';
import useBoxContext from './DealsContext';
import { kanbanIcon } from '../../Settings/utilsKanban';

const Col = styled.div`
  height: 67vh;
  position: relative;
  min-width: 250px;
  padding: 7px;
  overflow-y: hidden;
`;

const Title = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: left;
  top: 7px;
  width: calc(100% - 14px);
  text-align: left;
  height: 40px;
  align-items: center;
  padding: 0 14px;
  #icon {
    color: #fff;
  }
  #label {
    color: #fff;
    margin-left: 12px;
  }
`;
const CardContainer = styled.div`
  position: absolute;
  top: 57px;
  width: calc(100% - 14px);
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  overflow-y: auto;
`;

const KanbanColumn = ({ column: { _id, label, icon, color } }) => {
  const { updateDeal, deals } = useBoxContext();
  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop: async (item) => {
      const body = {
        status: _id,
        is_archived: label === 'Archives'
      };
      await updateDeal(item.id, body);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <div style={{ 'border-right': '1px solid rgba(0, 0, 0, 0.25)' }}>
      <Col ref={drop}>
        <Title>
          <div id="icon">
            {kanbanIcon.filter((ic) => ic.label === icon)[0]?.value}
          </div>
          <div id="label" style={{ lineHeight: 1 }}>
            {label.toUpperCase()}
          </div>
        </Title>
        <CardContainer>
          {deals
            .filter((deal) => deal.status._id === _id && !deal.is_archived)
            .map((deal) => (
              <Repository deal={deal} key={deal.name} />
            ))}
        </CardContainer>
      </Col>
    </div>
  );
};

KanbanColumn.propTypes = {
  column: PropTypes.shape({
    _id: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string
  }).isRequired
};

export default KanbanColumn;
