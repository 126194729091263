/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */

const getTotalsMattersShapings = (values, datas) => {
  const elements = [];
  let allShapings = [];
  const totalSurfaces = [];
  for (const [key, value] of Object.entries(values)) {
    if (key.split('_')[0] === 'section') {
      if (value?.elements?.length > 0) {
        elements.push(value.elements);
      }
    }
  }
  const totals = elements.flat().map(({ shaping, sizes, thikness }) => {
    const matter = datas.matters.find(({ _id }) => _id === shaping);
    const selectedThikness = matter?.pricing.find(
      ({ size }) => size === thikness
    );
    const buy_price = selectedThikness?.buy_price;
    const coef = matter?.coef?.coef;
    const lost_cost = matter?.lostCost || 0;
    const delivery_cost = matter?.deliveryCost || 0;
    const price = (
      (buy_price / (1 - lost_cost)) * coef +
      delivery_cost
    ).toFixed(2);

    let surfaces = sizes?.map(({ width, height }) => {
      const surface = (width / 1000) * (height / 1000);
      return Number(surface.toFixed(2));
    });

    if (surfaces?.length) {
      surfaces = surfaces.reduce((acc, value) => acc + value, 0);
    } else {
      surfaces = 0;
    }

    totalSurfaces.push(surfaces);
    const shapingsList = sizes?.map(({ shapings }) => shapings);

    const results = shapingsList?.flat().map((item) => {
      return item?.unit_price * item?.nb || 0;
    });
    allShapings.push(results);
    return Number((price * surfaces).toFixed(2));
  });

  const totalMattersBrut = totals.length ? totals.reduce((a, b) => a + b) : 0;
  const matterDiscount =
    (values.totals?.discountMatters * totalMattersBrut) / 100 || 0;
  let totalMattersNet = totalMattersBrut - matterDiscount;

  if (
    allShapings &&
    allShapings.length &&
    allShapings[0] &&
    allShapings[0].length
  ) {
    allShapings = allShapings.flat().reduce((a, b) => a + b);
  } else {
    allShapings = 0;
  }

  if (values.totals?.comPro > 0) {
    const mtComMatters = totalMattersNet * (values.totals?.comPro / 100);
    totalMattersNet += mtComMatters;
    allShapings *= (100 + values.totals?.comPro) / 100;
  }
  return {
    matters: totalMattersNet,
    shapings: allShapings,
    totalSurfaces
  };
};

const getTotalsLay = (values, datas) => {
  let elements = [];
  if (values.placed?.without_lay) return 0;
  if (values.placed) {
    for (const [, value] of Object.entries(values.placed)) {
      const item = datas.labors?.find(
        ({ _id, category }) => _id === value && category !== 'lay'
      );
      if (item) elements.push(item.sold_price);
    }
    if (elements.length) {
      elements = elements.reduce((a, b) => a + b);
    } else {
      elements = 0;
    }
  }

  if (values.totals?.comPro > 0 && values.totals.comWithLay) {
    elements *= (100 + values.totals?.comPro) / 100;
  }

  if (Array.isArray(elements)) elements = 0;

  return elements;
};

const getTotalsLabor = (values, datas, surfaces) => {
  const { placed, totals } = values;

  let total = 0;

  if (placed && placed?.without_lay) return 0;
  if (placed && placed?.lay) {
    const layPrice = datas.labors?.find(({ _id }) => _id === placed?.lay);
    const totalSurfaces = surfaces.length
      ? surfaces.reduce((a, b) => a + b)
      : 0;
    total = layPrice.sold_price * totalSurfaces;
  }

  if (totals?.comPro > 0 && totals.comWithLay) {
    total *= (100 + totals?.comPro) / 100;
  }

  return Number(total.toFixed(2));
};

const getTotalsEcoTax = (values, datas) => {
  let total = 0;
  if (values.ecotax) {
    for (const [key, value] of Object.entries(values.ecotax)) {
      const item = datas.labors?.find((labor) => labor?.eco_tax_type === key);
      total += item?.sold_price * value || 0;
    }
    return total;
  }

  if (values.totals?.comPro > 0 && values.totals.comWithLay) {
    total *= (100 + values.totals?.comPro) / 100;
  }

  return total;
};
const containsUndefined = (arr = []) => {
  const result = arr.filter((item) => {
    if (item === undefined || item === null) return false;
    return Object.values(item).every((value) => value !== undefined);
  });
  return result;
};

const getTotalsProducts = (values, datas) => {
  let totals = 0;
  const removeUndefined = containsUndefined(values?.products);

  totals = removeUndefined?.map(({ product, quantity }) => {
    const prod = datas.products?.find(({ _id }) => _id === product);
    return prod ? prod.sold_price * quantity : 0;
  });

  if (totals?.length) totals = totals.reduce((a, b) => a + b, 0);

  if (values.totals?.comPro > 0 && values.totals.comWithLay) {
    totals *= (100 + values.totals?.comPro) / 100;
  }

  return Array.isArray(totals) ? 0 : totals;
};

const getTotalsAccessory = (values) => {
  let total = 0;
  const removeUndefined = containsUndefined(values?.accessories);
  removeUndefined.forEach(({ price, quantity }) => {
    total += price * quantity;
  });

  if (values.totals?.comPro > 0) {
    total *= (100 + values.totals?.comPro) / 100;
  }

  return total;
};

const getTotalProClient = (
  totals,
  totalLay,
  totalLabor,
  totalEcoTax,
  totalProducts,
  totalsAccessories,
  totalDelivery,
  values
) => {
  const total =
    Number(totals.matters.toFixed(2)) +
    Number(totals.shapings.toFixed(2)) +
    Number(totalLay.toFixed(2)) +
    Number(totalLabor.toFixed(2)) +
    Number(totalEcoTax.toFixed(2)) +
    Number(totalProducts.toFixed(2)) +
    Number(totalDelivery.toFixed(2)) +
    Number(totalsAccessories.toFixed(2));

  const com = values.totals?.comPro || 0;
  let totalClient = total;
  let mtComPro = 0;
  let totalPro = total;

  if (values.totals?.comWithLay && com > 0) {
    totalPro /= Number(((com + 100) / 100).toFixed(2));
  }
  if (!values.totals?.comWithLay && com > 0) {
    const result =
      Number(totalsAccessories.toFixed(2)) +
      Number(totals.matters.toFixed(2)) +
      Number(totals.shapings.toFixed(2));
    mtComPro = result - Number((result / (com + 100) / 100).toFixed(2));
    totalPro = total - mtComPro;
  }

  if (values.totals?.discountCustomer) {
    totalClient -= Number(
      (totalClient * (values.totals.discountCustomer / 100)).toFixed(2)
    );
  }

  return { totalPro, totalClient };
};

const getTotalsTTC = (values, { totalClient }, { tvas }) => {
  let amount = 0;
  const tva = tvas?.find(({ _id }) => _id === values.totals?.tva);
  if (tva?.amount) amount = (tva.amount * totalClient) / 100;
  return Number(totalClient) + Number(amount);
};

const getDeliveryTotal = (values) => {
  let delivery = values.placed?.deliveryCost || 0;

  if (values.totals?.comPro > 0 && values.totals.comWithLay) {
    delivery *= (100 + values.totals?.comPro) / 100;
  }

  return delivery;
};

const getTotals = (form, datas) => {
  const values = form.getFieldValue();
  const totals = getTotalsMattersShapings(values, datas);
  const totalLay = getTotalsLay(values, datas);
  const totalLabor = getTotalsLabor(values, datas, totals.totalSurfaces);
  const totalEcoTax = getTotalsEcoTax(values, datas);
  const totalProducts = getTotalsProducts(values, datas);
  const totalsAccessories = getTotalsAccessory(values);
  const totalDelivery = getDeliveryTotal(values);

  const finals = getTotalProClient(
    totals,
    totalLay,
    totalLabor,
    totalEcoTax,
    totalProducts,
    totalsAccessories,
    totalDelivery,
    values
  );
  const totalsTTC = getTotalsTTC(values, finals, datas);

  const tva = datas.tvas?.find(({ _id }) => _id === values.totals?.tva);

  return {
    totalMatters: totals.matters,
    totalShapings: totals.shapings,
    totalLay,
    totalLabor,
    totalEcoTax,
    totalProducts,
    totalLayLabor: totalLabor + totalLay,
    totalPro: finals.totalPro,
    totalClient: finals.totalClient,
    totalWithoutEcoTax:
      totals.matters +
      totals.shapings +
      totalLay +
      totalProducts +
      totalLabor +
      totalDelivery +
      totalsAccessories,
    totalsTTC,
    tva,
    deliveryCost: totalDelivery,
    totalsAccessories
  };
};

export default getTotals;
