import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Steps, message } from 'antd';
import moment from 'moment';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ConfiguratorButtons from './ConfiguratorButtons';
import TabsPieces from './TabsPieces';
import GlobalForm from './GlobalForm';
import ProductsForm from './ProductsForm';
import PlacedForm from './PlacedForm';
import RecapsForm from './RecapsForm';
import useconfiguratorContext from './ConfiguratorContext';
import useParentContext from '../context';
import useAuthContext from '../../../contexts/AuthContext';

const { Step } = Steps;

const CustomStep = styled.div`
  cursor: pointer;
`;

const Configurator = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const [initialSection, setInitialSection] = useState(1);
  const {
    form,
    currentStep,
    setCurrentStep,
    refresh,
    setRefresh,
    initialValues,
    setInitFilters,
    setActiveKey,
    datas
  } = useconfiguratorContext();
  const { tvas, matters } = datas;
  const { dispatchAPI } = useAuthContext();
  const { createResource, updateResource, getQuote } = useParentContext();
  const [quoteNumber, setQuoteNumber] = useState('');
  const [quoteStatus, setQuoteStatus] = useState('');
  const [isPro, setIsPro] = useState(false);
  const [isProVisible, setIsProVisible] = useState(false);
  const [choice, setChoice] = useState('');
  const [activeSection, setActiveSection] = useState('1');

  const getDeal = async () => {
    const { data: deal } = await dispatchAPI('GET', {
      url: `/deals/${state.dealId}`
    });
    if (deal?.professionals?.length > 0) setIsProVisible(true);
    form.setFieldsValue({
      infos: {
        commercial_site: deal?.site._id || '',
        customer: deal?.customers?.length > 0 ? deal.customers[0]._id : '',
        isPro: deal?.professionals?.length > 0 ? 'checked' : '',
        profesional:
          deal?.professionals?.length > 0 ? deal.professionals[0]._id : ''
      },
      totals: {
        comPro:
          deal?.professionals?.length > 0
            ? deal.professionals[0].commission
            : ''
      }
    });
  };
  useEffect(() => {
    setCurrentStep(0);
    if (id || state?.order?.quote?._id) {
      (async () => {
        const idQuote = id || state?.order?.quote?._id;
        let quote = await getQuote(idQuote);
        if (quote?.status) setQuoteStatus(quote?.status);
        if (quote?.number) setQuoteNumber(`n°${quote?.number}`);
        let initialSectionCount = 0;
        Object.keys(quote).forEach((key) => {
          if (
            key.startsWith('section_') &&
            quote[`section_${initialSectionCount + 1}`]?.project_type
          ) {
            initialSectionCount += 1;
          }
          setInitialSection(initialSectionCount);
        });
        if (quote.infos && quote.infos.profesional) {
          setIsPro(true);
          quote = { ...quote, infos: { ...quote.infos, isPro: true } };
        }
        form.setFieldsValue({
          ...quote,
          order: state?.order?._id || null,
          totals: {
            ...quote.totals,
            tva: quote?.totals?.tva_id
          },
          placed: {
            ...quote.placed,
            deliveryCost: quote?.totals?.deliveryCost || null,
            lay_date: quote.placed?.lay_date
              ? moment(quote.placed?.lay_date)
              : undefined,
            measurement_date: quote.placed?.measurement_date
              ? moment(quote.placed?.measurement_date)
              : undefined
          }
        });
      })();
    } else {
      form.resetFields();
    }
  }, [id, state]);
  useEffect(() => {
    if (state?.fromCRM) {
      getDeal();
    }
  }, [state?.fromCRM]);

  useEffect(() => setInitFilters(true), []);

  useEffect(() => {
    if (currentStep === 4) {
      const body = form.getFieldsValue();
      Object.keys(body).forEach((key) => {
        if (key.startsWith('section_')) {
          const section = body[key];
          if (section.elements) {
            section.elements.forEach((element) => {
              if (element.sizes) {
                if (element.sizes.length === 0) {
                  setActiveKey('2');
                  setCurrentStep(1);
                  message.error(
                    'Veuillez bien remplir tous les champs dimensions'
                  );
                }
              } else {
                setActiveKey('2');
                setCurrentStep(1);
                message.error(
                  'Veuillez bien remplir tous les champs dimensions'
                );
              }
            });
          }
        }
      });
    }
  }, [currentStep]);

  const onValuesChange = (value) => {
    if (value.ecotax) setRefresh(!refresh);
    if (value.totals) setRefresh(!refresh);
  };

  const fieldsByStep = ['infos', 'section', 'product'];

  const addToDeal = async (dealId, quoteId) => {
    const { data: deal } = await dispatchAPI('GET', {
      url: `/deals/${dealId}`
    });
    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${dealId}/quotes`,
        body: {
          quotes: [...deal.quotes, { quote: quoteId }]
        }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleSubmit = async (status) => {
    await form
      .validateFields()
      .then(async (body) => {
        const tva = tvas.find((tva) => tva._id === body.totals.tva);

        const newBody = {
          ...body,
          totals: {
            ...body.totals,
            tva: tva.amount,
            tva_id: tva._id
          }
        };

        Object.entries(newBody).forEach(([key, value]) => {
          if (key.startsWith('section') && value.elements) {
            value.elements = value.elements.map((element) => {
              const matter = matters.find(
                (matter) => matter._id === element.shaping
              );
              const unit_price = matter?.pricing.find(
                (price) => price.size === element.thikness
              )?.buy_price;

              return {
                ...element,
                delivery_cost: matter?.deliveryCost || 0,
                unit_price: unit_price || 0,
                lost_cost: matter?.lostCost || 0,
                coef: matter?.coef?.coef || 0
              };
            });
          }
        });

        Object.assign(newBody, { status });
        if (id) {
          updateResource(newBody, id);
        } else {
          const newId = await createResource({
            ...newBody,
            order: state?.order?._id || null
          });
          if (state.fromCRM) addToDeal(state.dealId, newId);
        }
        form.resetFields();
        setActiveKey('1');
      })
      .catch((errorInfo) => {
        errorInfo.errorFields.forEach((error) => {
          if (error.name[0].startsWith('accessories')) setCurrentStep(2);
          if (error.name.length === 4) setActiveKey('1');
          if (error.name.length > 4) setActiveKey('2');
          const step = fieldsByStep.findIndex((field) => {
            return field === error.name[0] || error.name[0].startsWith(field);
          });
          if (step !== -1) {
            setCurrentStep(step);
          }
          if (error.name[0].startsWith('section'))
            setActiveSection(error.name[0].charAt(error.name[0].length - 1));
        });
        message.error('Veuillez bien remplir tous les champs requis');
      });
  };

  return (
    <>
      <PageHeaderCustom
        title={
          id
            ? `${t(`quotations.form.title.edit`)} ${quoteNumber}`
            : t(`quotations.form.title.create`)
        }
        setCurrentStep={setCurrentStep}
      />
      <ContentCustom>
        <Form
          form={form}
          name="quotation"
          onValuesChange={onValuesChange}
          initialValues={initialValues}
        >
          <Steps
            current={currentStep}
            onChange={(step) => setCurrentStep(step)}
            className="quotation-form-steps"
          >
            <CustomStep as={Step} title={t('configurator.informations')} />
            <CustomStep as={Step} title={t('configurator.pieces')} />
            <CustomStep as={Step} title={t('configurator.products')} />
            <CustomStep as={Step} title={t('configurator.placed')} />
            <CustomStep as={Step} title={t('configurator.recaps')} />
          </Steps>
          <GlobalForm
            isPro={isPro}
            setValue={setChoice}
            form={form}
            isProVisible={isProVisible}
            setIsProVisible={setIsProVisible}
          />
          <TabsPieces
            initialSectionCount={initialSection}
            value={choice}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
          <ProductsForm />
          <PlacedForm />
          <RecapsForm />
          <ConfiguratorButtons
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleSubmit={handleSubmit}
            status={quoteStatus}
          />
        </Form>
      </ContentCustom>
    </>
  );
};

Configurator.propTypes = {};

export default Configurator;
