import moment from 'moment';

const SizeFilter = (sizes) => {
  let result = 0;

  if (!sizes) return 'N/R';

  const filteredSizes = sizes.filter((size) => !size.exit_date);
  result = filteredSizes.length;
  return result;
};

const totalCalculator = (buying_price, sizes, matter, biglength, bigwidth) => {
  let result = 0;

  sizes.forEach((size) => {
    if (size.exit_date) return;
    const { length, width } = size;
    if (size?.type === 'Chute') {
      const total =
        (((length / 1000) * width) / 1000) *
        (buying_price + matter?.deliveryCost);
      result += total;
    } else {
      const total =
        (((biglength / 1000) * bigwidth) / 1000) *
        (buying_price + matter?.deliveryCost);
      result += total;
    }
  });
  return result;
};

export const headers = [
  {
    label: 'pieces.column.buy_site',
    render: ({ buy_site }) => buy_site?.label || 'N/R'
  },
  {
    label: 'pieces.column.created_at',
    render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY') || 'N/R'
  },
  {
    label: 'pieces.column.supplier',
    render: ({ supplier }) => supplier?.name || 'N/R'
  },
  {
    label: 'pieces.column.matter',
    render: ({ matter }) =>
      matter ? `${matter?.label} - ${matter?.color}` : 'N/R'
  },
  {
    label: 'pieces.column.batch_number',
    render: ({ batch_number }) => batch_number || 'N/R'
  },
  {
    label: 'pieces.column.completions',
    render: ({ matter }) => matter?.completions || 'N/R'
  },
  {
    label: 'pieces.column.thickness',
    render: ({ thickness }) => thickness || 'N/R'
  },
  {
    label: 'pieces.column.length',
    render: ({ length }) => length || 'N/R'
  },
  {
    label: 'pieces.column.width',
    render: ({ width }) => width || 'N/R'
  },
  {
    label: 'pieces.column.sizes',
    render: ({ sizes }) => SizeFilter(sizes) || 'N/R'
  },
  {
    label: 'pieces.column.buying_price',
    render: ({ buying_price }) => buying_price || 'N/R'
  },
  {
    label: 'pieces.column.value',
    render: ({ value }) => value || 'N/R'
  },
  {
    label: 'pieces.column.transport',
    render: ({ matter }) => matter?.deliveryCost || 'N/R'
  },
  {
    label: 'pieces.column.value_in_euro',
    render: ({ length, width, buying_price, sizes, matter }) =>
      totalCalculator(buying_price, sizes, matter, length, width) || 'N/R'
  }
];
