import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import getTotals from './utils';

const ConfiguratorContext = createContext({});

export const ConfiguratorContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const { state } = useLocation();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [datas, setDatas] = useState({});
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [initFilters, setInitFilters] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState('1');

  const getDatas = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quotes/form/datas'
      });
      setDatas(data);
      setLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, [refreshData, state]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  useEffect(() => {
    if (currentStep === 4) setRefresh(!refresh);
  }, [currentStep]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setActiveKey('1');
      form.resetFields();
      setCurrentStep(0);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const {
      totalMatters,
      totalShapings,
      totalLay,
      totalLabor,
      totalEcoTax,
      totalProducts,
      totalPro,
      totalClient,
      totalWithoutEcoTax,
      totalLayLabor,
      totalsTTC,
      tva,
      deliveryCost,
      totalsAccessories
    } = getTotals(form, datas);

    form.setFieldsValue({
      totals: {
        totalMatters,
        totalShapings,
        totalLay,
        totalLabor,
        totalEcoTax,
        totalProducts,
        totalWithoutEcoTax,
        totalPro,
        totalClient,
        totalLayLabor,
        totalsTTC,
        tva: tva?._id,
        deliveryCost,
        totalsAccessories
      }
    });
  }, [refresh, state]);

  const initialValues = {
    ecotax: {
      bathroom: 0,
      cook_inf_120: 0,
      cook_supp_120: 0,
      credence: 0,
      furniture: 0,
      table_plate: 0
    },
    totals: {
      comPro: 0,
      comWithLay: true,
      discountMatters: 0
    }
  };

  return (
    <ConfiguratorContext.Provider
      value={{
        datas,
        filters,
        setFilters,
        form,
        t,
        currentStep,
        setCurrentStep,
        setRefresh,
        refresh,
        setRefreshData,
        refreshData,
        activeKey,
        setActiveKey,
        initialValues,
        initFilters,
        setInitFilters,
        loading
      }}
    >
      {children}
    </ConfiguratorContext.Provider>
  );
};

ConfiguratorContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(ConfiguratorContext);
