const additionate = (a, b) => {
  let result = 0;
  result = (Number(a) + Number(b)).toFixed(2);
  return Number(result);
};

const multiplicate = (a, b) => {
  let result = 0;
  result = (Number(a) * Number(b)).toFixed(2);
  return Number(result);
};

const calculMtTva = (price, tva) => {
  let result = 0;
  result = (price * tva) / 100;
  return Number(result);
};

const totalColumn = (column, property) => {
  let result = 0;
  const sum = column.reduce((acc, curr) => {
    if (curr.type === 'title-type.discount' && property === 'mtTva') {
      return acc - Number(curr[`${property}`]);
    }
    if (curr.type === 'title-type.discount') return acc;
    return acc + Number(curr[`${property}`]);
  }, 0);
  result = Number(sum);
  return result;
};

const totalColumns = (column, property) => {
  let result = 0;
  const sum = column.reduce((acc, curr) => {
    return acc + Number(curr[`${property}`]);
  }, 0);
  result = sum;
  return result;
};

const truncateToSecondDecimal = (num) => Math.trunc(num * 100) / 100;

const sumByCode = (data) => {
  const result = {};

  const advancePayments = data.filter((item) => item.advance_payment);

  const updatedData = data.map((item) => {
    if (item.type === 'title-type.tva') {
      let advancePaymentMtTva = 0;
      advancePayments.forEach((advancePayment) => {
        advancePaymentMtTva += advancePayment.mtTva;
      });

      return {
        ...item,
        mtTva: Number((item.mtTva - advancePaymentMtTva).toFixed(2))
      };
    }

    return item;
  });

  updatedData.forEach((item) => {
    const truncatedTotalHT = truncateToSecondDecimal(item?.totalHT) || 0;
    const truncatedMtTva = truncateToSecondDecimal(item?.mtTva) || 0;
    const truncatedTotalTTC = truncateToSecondDecimal(item?.totalHT) || 0;
    Number(Number(truncatedTotalHT || 0).toFixed(2));
    if (!result[item.code]) {
      result[item.code] = { ...item };
    } else {
      if (item.advance_payment) {
        result[item.code].totalHT -= Number(
          Number(truncatedTotalHT).toFixed(2)
        );
        result[item.code].mtTva -= Number(Number(truncatedMtTva).toFixed(2));
      } else {
        result[item.code].totalHT += Number(
          Number(truncatedTotalHT || 0).toFixed(2)
        );
        result[item.code].mtTva += Number(Number(truncatedMtTva).toFixed(2));
      }
      result[item.code].totalTTC += Number(
        Number(truncatedTotalTTC).toFixed(2)
      );
    }
  });

  console.log('result', result);
  return Object.values(result);
};

const matterTotal = (data, size, coefs, com) => {
  let result = 0;
  let sellPrice = 0;
  let surface = 0;
  const { color, unit_price } = data;
  const coef = coefs.find((obj) => obj._id === color.coef);

  if (coefs.length > 0) {
    sellPrice =
      (unit_price / (1 - color.lostCost)) * coef.coef + color.deliveryCost;

    surface = ((size.height * size.width) / 1000000).toFixed(2);

    result = (sellPrice * surface * com).toFixed(2);
  }

  return Number(result);
};

const shapingTotal = (price, nb, com) => {
  let result = 0;
  result = (price * nb * com).toFixed(2);
  return Number(result);
};

const shapingFormat = (shapings, size, totals, thikness, com, result) => {
  const { height, width } = size;
  shapings.map(({ shaping_name, nb, unit_price }) => {
    const amountShapingHT = shapingTotal(unit_price, nb, com);
    const amountShapingTva = calculMtTva(amountShapingHT, totals.tva);

    result.push({
      type: shaping_name.category,
      quantity: `${nb} ${shaping_name.unit}`,
      label: `${shaping_name.label}, ${height} x ${width} cm, ${thikness} cm`,
      totalHT: amountShapingHT,
      tva: totals.tva,
      mtTva: amountShapingTva,
      totalTTC: additionate(amountShapingHT, amountShapingTva)
    });
    return true;
  });
};

const matterFormat = (datas, totals, coefs, com) => {
  const result = [];
  const { sizes, color, matter, thikness } = datas;

  sizes.map((size) => {
    const { height, width, shapings } = size;
    const amountMatterHT = matterTotal(datas, size, coefs, com);
    const amountTva = calculMtTva(amountMatterHT, totals.tva);

    result.push({
      type: 'title-type.matter',
      quantity: `${1}`,
      label: `${matter} ${color.color} ${color.completions}, ${height} x ${width} cm, ${thikness} cm`,
      totalHT: amountMatterHT,
      tva: totals.tva,
      mtTva: amountTva,
      totalTTC: additionate(amountMatterHT, amountTva)
    });

    shapingFormat(shapings, size, totals, thikness, com, result);

    return true;
  });
  return result;
};

const matterAndShapingFormat = (datas, invoice, coefs) => {
  const { totals } = invoice;
  let result = [];
  let com = 1;

  if (totals.comPro || totals.comPro > 0) com = (totals.comPro + 100) / 100;

  result = matterFormat(datas, totals, coefs, com);

  return result;
};

const getMattersAndShapings = (datas, coefs) => {
  let matters = Object.entries(datas)
    .filter(([key]) => key.startsWith('section'))
    .reduce((acc, [, value]) => {
      const elements = value.elements.filter(
        (obj) => Object.keys(obj).length > 0
      );
      acc.push(...elements);
      return acc;
    }, []);

  matters = matters
    .map((matter) => matterAndShapingFormat(matter, datas, coefs))
    .flat();
  return matters;
};

const getProducts = (data) => {
  let com = 1;
  const result = [];
  const { totals, products } = data;

  if (totals.comWithLay && (totals.comPro || totals.comPro > 0))
    com = (totals.comPro + 100) / 100;

  products.map(({ quantity, unit_price, product, category }) => {
    const amountProductHT = (multiplicate(unit_price, quantity) * com).toFixed(
      2
    );
    const amountProductTva = calculMtTva(amountProductHT, totals.tva);
    const { label } = product;

    result.push({
      type: 'title-type.product',
      quantity: `${quantity}`,
      label: `${category}, ${label}`,
      totalHT: Number(amountProductHT),
      tva: totals.tva,
      mtTva: amountProductTva,
      totalTTC: additionate(amountProductHT, amountProductTva)
    });

    return true;
  });

  return result;
};

const getAccessories = (data) => {
  let com = 1;
  const result = [];
  const { accessories, totals } = data;

  if (totals.comPro || totals.comPro > 0) com = (totals.comPro + 100) / 100;

  accessories.map(({ quantity, name, price }) => {
    const amountAccessoryHT = Number(
      (multiplicate(price, quantity) * com).toFixed(2)
    );
    const amountAccessoryTva = calculMtTva(amountAccessoryHT, totals.tva);
    result.push({
      type: 'title-type.accessory',
      quantity: `${quantity}`,
      label: `${name}`,
      totalHT: amountAccessoryHT,
      tva: totals.tva,
      mtTva: amountAccessoryTva,
      totalTTC: additionate(amountAccessoryHT, amountAccessoryTva)
    });
    return true;
  });

  return result;
};

const getLayAndLabor = (data) => {
  const { totals } = data;
  let result = {};

  if (!totals?.totalLayLabor || totals.totalLayLabor <= 0) return [];

  const amountLayAndLaborHT = Number(totals.totalLayLabor);
  const amountLayAndLaborTva = calculMtTva(amountLayAndLaborHT, totals.tva);

  result = {
    type: 'title-type.lay',
    quantity: '-',
    label: 'message-type.LayLabor',
    totalHT: amountLayAndLaborHT,
    tva: totals.tva,
    mtTva: amountLayAndLaborTva,
    totalTTC: additionate(amountLayAndLaborHT, amountLayAndLaborTva)
  };

  return result;
};

const getDeliveryCost = (data) => {
  const { totals, infos } = data;
  let result = {};

  if (!totals.deliveryCost) return [];
  const { deliveryCost, tva } = totals;

  const amountdeliveryCostHT = Number(deliveryCost);
  const amountdeliveryCostTva = calculMtTva(amountdeliveryCostHT, tva);

  result = {
    type: 'title-type.place',
    quantity: '-',
    label: `${infos.customer.address.city}`,
    totalHT: amountdeliveryCostHT,
    tva,
    mtTva: amountdeliveryCostTva,
    totalTTC: additionate(amountdeliveryCostHT, amountdeliveryCostTva)
  };

  return result;
};
const getEcoTaxe = (data) => {
  const { totals } = data;
  let result = {};

  if (!totals.totalEcoTax) return [];
  const { totalEcoTax, tva } = totals;

  const amountTotalEcoTaxHT = Number(totalEcoTax.toFixed(2));
  const amountTotalEcoTaxTva = calculMtTva(amountTotalEcoTaxHT, tva);

  result = {
    type: 'title-type.ecotax',
    quantity: '-',
    label: 'message-type.ecotax',
    totalHT: amountTotalEcoTaxHT,
    tva,
    mtTva: amountTotalEcoTaxTva,
    totalTTC: additionate(amountTotalEcoTaxHT, amountTotalEcoTaxTva)
  };

  return result;
};

export default {
  getMattersAndShapings,
  calculMtTva,
  additionate,
  multiplicate,
  getProducts,
  getAccessories,
  getLayAndLabor,
  getDeliveryCost,
  getEcoTaxe,
  totalColumn,
  totalColumns,
  sumByCode
};
